import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import SEO from '../../components/seo';
import SidePhoto from '../../components/SidePhoto';
import TrailTitle from '../../components/TrailTitle';
import { graphql } from 'gatsby';

const PartnersPage = ({ data, location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!open);

    return () => {
      setOpen(!open);
    };
  }, []);

  return (
    <>
      <SEO title='Rekan' />
      <Img fluid={data.partner.cover.value.childImageSharp.fluid} />
      <Row
        css={{
          minHeight         : '75vh',
          backgroundImage   : `url(${data.bg.childImageSharp.fixed.src})`,
          backgroundRepeat  : 'no-repeat',
          backgroundPosition: 'top right',
        }}
        className='pt-5'>
        <Col
          sm        = {'5'}
          className = 'd-flex flex-column align-items-center align-content-center'
          css       = {{ justifyContent: 'space-between' }}>
          <TrailTitle open={open} className='pb-2 mb-5'>
            <div>Partner</div>
            <div>RSGM IIK BW</div>
          </TrailTitle>
          <SidePhoto>
            <Img fluid={data.partner.side.value.childImageSharp.fluid} />
          </SidePhoto>
        </Col>
        <Col className='d-flex flex-column align-items-center align-content-center pl-3 pr-3 pt-1'>
          <ReactMarkdown
            source     = {data.partner.content.value}
            escapeHtml = {false}
          />
          <ol css={{ listStylePosition: 'outside' }} className='pl-3'>
            {data.partners.edges.map(
              ({ node: { cockpitId, name, description, logo } }) => (
                <li key={cockpitId}>
                  <div className='d-flex flex-row align-items-center align-content-center'>
                    <strong className='mr-2'>{name.value}</strong>
                    {logo && <Img fixed={logo.value.childImageSharp.fixed} />}
                  </div>
                  <ReactMarkdown
                    source     = {description.value}
                    escapeHtml = {false}
                  />
                </li>
              )
            )}
          </ol>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  {
    partner: cockpitPartner {
      content {
        type
        value
      }
      cover: cover_photo {
        type
        value {
          childImageSharp {
            fluid(maxWidth: 1920, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      side: side_photo {
        type
        value {
          childImageSharp {
            fluid(toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    partners: allCockpitPartners(filter: { display: { value: { eq: true } } }) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          description {
            type
            value
          }
          logo {
            type
            value {
              childImageSharp {
                fixed(width: 48, toFormat: WEBP) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default PartnersPage;
